import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import { Tooltip, Typography } from '@material-ui/core'
import messages from '@utils/messages'
import { useUserSession } from '@utils/useUserSession'
import { ShippingTypes } from '@utils/searchUtils'
import ShipsTodayIcon from '@icons/ShipsTodayIcon'
import FreeFreightIcon from '@icons/FreeFreightIcon'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'

const useStyles = makeStyles((theme: Theme) => ({
  shipIconContainer: {
    display: 'flex',
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
  },
  shipsTodayIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(25.6),
  },
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: theme.typography.pxToRem(260),
    fontSize: theme.typography.pxToRem(12),
    boxShadow: '0 6px 13px 0 rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(6, 5),
  },
}))(Tooltip)

const timeByCountry = {
  US: '6:00 PM EST',
  DE: '5:30 PM CET',
  IN: '2:30 PM IST',
  CN: '5:00 PM CST',
  JP: '3:00 PM JST',
  KR: '3:00 PM KST',
  FR: '4:00 PM CET',
  GB: '3:30 PM GMT',
}

interface ShippingDetailProps {
  type: ShippingTypes
  className?: string
  gaSection?: string
  gaComponent?: string
  material?: ValidMaterialPricingDetailFragment
}

const ShippingDetails = ({
  type,
  className,
  gaSection,
  gaComponent,
  material,
}: ShippingDetailProps) => {
  const classes = useStyles()
  const { userSession } = useUserSession()
  const shipsToday = type === ShippingTypes.SameDay
  return (
    <HtmlTooltip
      interactive
      onClose={() =>
        shipsToday &&
        sendPricingAvailabilityInteractionEvent({
          action: 'hover ships today',
          section: gaSection,
          detail: gaSection === 'quick order' ? 'manual entry' : undefined,
          component: gaComponent || '',
          elementType: 'icon',
          material: material,
        })
      }
      title={
        <>
          <Typography>
            {shipsToday ? (
              <FormattedMessage {...messages.SHIPS_TODAY} />
            ) : (
              <FormattedMessage {...messages.FREE_FREIGHT} />
            )}
          </Typography>
          <div style={{ paddingTop: '10px', fontWeight: 'normal' }}>
            {shipsToday ? (
              <FormattedMessage
                {...messages.SHIPS_TODAY_MESSAGE}
                values={{
                  shippingTime: timeByCountry[userSession.country],
                }}
              />
            ) : (
              <FormattedMessage {...messages.FREE_FREIGHT_MESSAGE} />
            )}
          </div>
        </>
      }
    >
      <span className={className ?? classes.shipIconContainer}>
        {shipsToday ? (
          <ShipsTodayIcon
            color="secondary"
            className={classes.shipsTodayIcon}
          />
        ) : (
          <FreeFreightIcon color="secondary" fontSize="large" />
        )}
      </span>
    </HtmlTooltip>
  )
}

export default ShippingDetails
